import React from "react"
import { Link } from "gatsby"
import { Container } from "./Container"
import { Images } from "./Images"

export const StpContent = () => (
  <section className="">
    <Container>
      <div className="services-content-wrapper">
        <p>
          The majority of the contaminants from waste-water or sewage is removed
          by treatment and this process produces a liquid effluent suitable for
          disposal to the natural environment and a sludge. Bacteria present in
          the nature aid the removal of organic matter from effluents by
          splitting them to simpler compounds. Depending on the bacteria this
          may be happening in aerobic conditions or anaerobic conditions.
        </p>
        <p>
          In the natural process, the microorganism takes longer time to digest
          the organic matter due to less number of bacteria in action or due to
          unfavorable environmental conditions.
        </p>
        <p>
          To know more,{" "}
          <Link to="/contact-us" className="text-secondary underline">
            contact us.
          </Link>
        </p>
        <div>
          <h2 className="font-sans font-bold text-lg my-4">Advantages</h2>
          <ul className="list-inside list-disc">
            <li>
              Operational cost is about 60% less than conventional methods
            </li>
            <li>Proven technology with many Installations</li>
            <li>Odour-free operation</li>
            <li>
              Less slurry formation; hence any permanent slurry removal systems
              can be used
            </li>
            <li>High tolerance against detergents, soaps, oil and fat</li>
            <li>
              All tanks can be covered – utilization of the treatment area is
              possible
            </li>
            <li>
              Automatic Operation reduces the operating cost and provides ease
              for operation
            </li>
          </ul>
        </div>
      </div>
      <section className="mb-10">
        <div className="font-sans font-bold text-primary text-xl mb-4 text-center">
          Gallery
        </div>
        <Images
          className="flex flex-wrap p-4 justify-center z-40"
          filterByText="stp"
          imageClassName="w-32 m-1 cursor-pointer hover:shadow-lg"
          enableGallery={true}
        />
      </section>
    </Container>
  </section>
)
