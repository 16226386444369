import React from "react"
import { Container } from "./Container"
import { Link } from "gatsby"
const servicesList = [
  {
    uri: "/water-treatment-plants",
    name: "Water Treatment Plants",
  },
  {
    uri: "/sewage-treatment-plants",
    name: "Sewage Treatment Plants",
  },
  { uri: "/swimming-pools", name: "Swimming Pools & Fountains" },
]

export const ServicesMenu = props => {
  const uri = props.uri
  return (
    <section className="flex flex-col text-center mb-6 md:w-120">
      <Container>
        <h1 className="font-sans font-bold text-primary text-xl mb-6">
          Our Services
        </h1>
        {servicesList.map(service => {
          let selectedStyles =
            service.uri === uri
              ? "bg-primary-light font-semibold"
              : "bg-primary hover:font-semibold"
          console.log(selectedStyles)
          const className = `block font-sans px-2 py-3 border-b border-black-10  text-white ${selectedStyles}`
          return (
            <Link className={className} to={service.uri} key={service.uri}>
              {service.name}
            </Link>
          )
        })}
      </Container>
    </section>
  )
}
