import React from "react"
import { Container } from "../components/Container"
import { Header } from "../components/HeaderSection"
import { ServicesMenu } from "../components/ServicesMenu"
import { StpContent } from "../components/StpContent"
import { Footer } from "../components/Footer"
import { AnimationWrapper } from "../components/AnimationWrapper"
import { SplitLetters } from "../components/SplitLetters"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const SewageTreatment = ({ uri, data }) => (
  <section className="bg-body-lightest">
    <SEO
      title="Sewage treatment plants (STP)"
      description="sewage or effluent treatment plants with high durability, proven technology and automatic operation at affordable cost"
    />
    <Header />
    <section className="relative mb-6 md:mb-16">
      <Img
        className="h-72"
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
      <AnimationWrapper animationType="fadeIn" delay="0">
        <div className="h-72 absolute inset-0 bg-black-50 flex items-center justify-center">
          <Container>
            <h1 className="services-section--header">
              <SplitLetters text="Sewage Treatment Plants" />
            </h1>
          </Container>
        </div>
      </AnimationWrapper>
    </section>
    <div className="flex flex-col-reverse items-center lg:flex-row lg:justify-between lg:items-start">
      <ServicesMenu uri={uri} />
      <StpContent />
    </div>
    <Footer />
  </section>
)

export default SewageTreatment

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "stp_hero.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
